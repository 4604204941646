import React, { FunctionComponent } from 'react';
import { Container, Info, NoWrap } from './style';

export const Footer: FunctionComponent<{}> = () => {
    return (
        <Container>
            <Info>Este sitio no usa cookies! :)</Info>
            <Info>
                <NoWrap>&copy; 2023</NoWrap>{' '}
                <NoWrap>THE CODER CAVE. ALL RIGHTS RESERVED.</NoWrap>
            </Info>
        </Container>
    );
};
